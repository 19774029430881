export enum ROUTERS {
  "sushi" = "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
  "uniswap" = "0xf164fC0Ec4E93095b804a4795bBe1e041497b92a",
}

export enum TOKENS {
  UNI_TOKEN = "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
  WETH = "0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6",
}

export enum CONTRACTS {
  SWAP = "0x27B8382b89B51feC1BdD1262a5163CD5eAc54CF9",
  MARKETPLACE = "0xEF4eECf880D9008A18b04C3E8Ed5f00d36305F4f",
  NFT = "0x6588d9DD2c7c8360befA7240fB2778D8eA2542dD",
}

export enum STARKNET_CONTRACTS {
  AUCTION = "0x013d8580202fdb6e3f0b36f4cdfedd68c3b93fc4c3fcf05956a1f0051aa29e08",
}
