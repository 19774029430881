import { Navbar } from "components";
import styles from "./Profile.module.scss";

const Profile = () => {
  return (
    <>
      <Navbar />
    </>
  );
};

export { Profile };
